import React from 'react';

import Grid from '@mui/material/Grid';
import Link from '@material-ui/core/Link';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';

import mediaHeader from '../images/media_header.svg';

const Media = () => {

  return (
    <Page title='Media'>
      {/* Hero */}
      <Section wide>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Phone showing the Innowell App Dashboard' src={mediaHeader}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>Media</Typography>
            <Typography type='paragraph' preset={2}>Learn more about the discussions being had and how Innowell can help your people open up better mental health care. </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section variant='wide'>
        <Grid container direction='row-reverse'>
          <Grid item xs={12}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: 4,
            }}
          >
            <Link href="https://theconversation.com/coronavirus-has-boosted-telehealth-care-in-mental-health-so-lets-keep-it-up-137381">
              <Typography type='paragraph' preset={1}>Coronavirus has boosted telehealth care in mental health, so let’s keep it up
              </Typography>
            </Link>
            <Typography type="paragraph" preset={2}>The Conversation, May 6 2020</Typography>
          </Grid>

          <Grid item xs={12}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: 4,
            }}
          >
            <Link href="https://www.theage.com.au/lifestyle/health-and-wellness/be-thankful-the-pandemic-came-in-an-era-of-sophisticated-communication-20200418-p54l1r.html">
              <Typography type='paragraph' preset={1}>Be thankful the pandemic came in an era of sophisticated communication
              </Typography>
            </Link>
            <Typography type="paragraph" preset={2}>The Age, April 18 2020</Typography>
          </Grid>

          <Grid item xs={12}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: 4,
            }}
          >
            <Link href="https://www.abc.net.au/news/2020-03-27/the-drum:-friday-march-27th/12098644">
              <Typography type='paragraph' preset={1}>How to look after your mental health
              </Typography>
            </Link>
            <Typography type="paragraph" preset={2}>The Drum ABC, March 27 2020</Typography>
          </Grid>

          <Grid item xs={12}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: 4,
            }}
          >
            <Link href="https://www.smh.com.au/national/circle-of-care-model-proposed-for-youth-mental-health-20191101-p536ox.html">
              <Typography type='paragraph' preset={1}>'Circle of care' model proposed for youth mental health
              </Typography>
            </Link>
            <Typography type="paragraph" preset={2}>Sydney Morning Herald, November 4th 2019</Typography>
          </Grid>


        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://www.podomatic.com/podcasts/themja/episodes/2019-11-03T18_03_48-08_00">
            <Typography type='paragraph' preset={1}>PODCAST: A novel model for youth mental health care
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>The Medical Journal of Australia, November 4th 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://sydney.edu.au/news-opinion/news/2019/11/04/experts-detail-mental-health-model-that-puts-young-people-first-.html">
            <Typography type='paragraph' preset={1}>Youth mental health: right level of care needed, first time
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>University of Sydney, November 4th 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://askhealthnews.com/4918/australias-digital-mental-health-project-is-all-set-for-new-trials-after-winning-grant/">
            <Typography type='paragraph' preset={1}>Australia’s Digital Mental Health Project is All Set for New Trials After Winning Grant
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>Ask Health News, October 24th 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://www.dailytelegraph.com.au/lifestyle/health/the-rise-of-online-mental-health-treatments/news-story/b5cd2e2312c5e47378c356cccf255954?btr=e65fd657d0008ac7aec7c1134ad47e76">
            <Typography type='paragraph' preset={1}>The rise of online mental health treatments
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>Daily Telegraph, October 23rd 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://healthdispatch.com.au/news/bupa-teams-up-with-university-of-sydney-on-new-research-project">
            <Typography type='paragraph' preset={1}>Bupa teams up with University of Sydney on new research project
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>Health Dispatch, October 22nd 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://sydney.edu.au/news-opinion/news/2019/10/21/digital-mental-health-project-wins-industry-grant-for-new-trial.html">
            <Typography type='paragraph' preset={1}>Digital mental health project wins industry grant for new trial
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>University of Sydney, October 21st 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://www.abc.net.au/radionational/programs/drive/more-action-and-implementation-needed-for-mental-health-outcomes/11591680">
            <Typography type='paragraph' preset={1}>More action and implementation needed for mental health outcomes
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>ABC, October 10th 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://sydney.edu.au/news-opinion/news/2019/10/08/unique-online-platform-engages-youth-in-suicide-prevention-study.html">
            <Typography type='paragraph' preset={1}>Unique online platform engages youth in suicide prevention
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>University of Sydney, October 8th 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://www.mja.com.au/journal/2019/211/7/uberisation-mental-health-care-welcome-global-phenomenonl">
            <Typography type='paragraph' preset={1}>The “uberisation” of mental health care: a welcome global phenomenon?
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>Medical Journal of Australia, October 7th 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://www.dailytelegraph.com.au/news/nsw/new-online-platform-to-uberise-mental-health-care-for-teenagers-in-australia/news-story/ef011d189a5c38cbcebea55bfa703cea">
            <Typography type='paragraph' preset={1}>New online platform to ‘uberise’ mental health care for teenagers in Australia
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>Daily Telegraph, October 7th 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://www.smh.com.au/national/disruptive-digital-design-could-help-health-services-reach-vulnerable-20191006-p52y0c.html">
            <Typography type='paragraph' preset={1}>Disruptive digital design could help health services reach vulnerable
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>Sydney Morning Herald, October 7th 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://www.mja.com.au/journal/2019/211/7/project-synergy-co-designing-technology-enabled-solutions-australian-mental">
            <Typography type='paragraph' preset={1}>Project Synergy: co-designing technology-enabled solutions for mental health services
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>Medical Journal of Australia, October 7th 2019</Typography>
        </Grid>

        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          <Link href="https://www.abc.net.au/triplej/programs/hack/the-early-indicators-of-mental-illness-in-young-people/11458452">
            <Typography type='paragraph' preset={1}>Here are some of the early indicators of mental illness in young people
            </Typography>
          </Link>
          <Typography type="paragraph" preset={2}>ABC, August 28th 2019</Typography>
        </Grid>

      </Grid>
      </Section>

    </Page>
  )
};

export default Media;
